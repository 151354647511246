<template>
  <div>
    <div class="fixed top-0 left-0 w-full h-full">
      <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
    </div>
    <div v-if="!waitingSignature && !sendingSuccessful && !sendingInProgress">
      <div
        v-if="template"
        class="fixed w-modal-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4 py-3 border rounded-xl shadow-md"
      >
        <div class="flex items-center mb-5">
          <p class="font-medium text-black">
            <icon name="user-add" class="mr-1.5" />{{
              $t('templateSend.addRecipientsTxt')
            }}
          </p>
          <div class="grow"></div>
          <div
            @click="triggerCloseEvent"
            class="text-xl text-gray-400 mr-2 cursor-pointer leading-none"
          >
            <icon name="cross" class="text-sm fill-gray-400" />
          </div>
        </div>
        <p class="text-gray-400 mb-1">
          {{ $t('templateSend.shareLinkTxt') }}
        </p>
        <div
          v-if="template.data.badgeCategory != 'Ticket'"
          class="border-2 border-gray-200 h-28 overflow-y-scroll no-scrollbar rounded-lg py-2 px-2 mb-2"
          ref="emailListScrollDiv"
        >
          <div v-if="emailList.length > 0" class="flex flex-wrap gap-2 mb-1.5">
            <div
              v-for="(email, index) in emailList"
              :key="index"
              class="bg-violet-50 pl-1.5 pr-1 rounded-md"
            >
              <p class="text-gray-500">
                {{ email
                }}<span
                  @click="removeFromEmailList(index)"
                  class="cursor-pointer"
                  ><icon name="cross" class="fill-gray-400 text-xxs ml-2 pb-1"
                /></span>
              </p>
            </div>
          </div>
          <textarea
            class="w-full border-none border-transparent focus:border-transparent focus:ring-0 resize-none p-0"
            placeholder=""
            rows="10"
            v-model="emailInput"
            @keydown.enter.prevent="treatEmailInput"
            @focusout="treatEmailInput"
          />
        </div>
        <div
          v-else
          class="grow max-h-[12rem] flex flex-col border overflow-hidden rounded-lg my-10"
        >
          <div class="w-full flex">
            <p
              class="flex-1 text-center border-b border-r text-sm uppercase font-medium px-3 py-2"
            >
              <icon name="unfold-more" class="mr-1" />
              Email
            </p>
            <p
              v-if="template.data.eventDetails.name"
              class="flex-1 text-center border-b border-r text-sm uppercase font-medium px-3 py-2"
            >
              <icon name="unfold-more" class="mr-1" />
              Name
            </p>
            <p
              v-if="template.data.eventDetails.seat"
              class="flex-1 text-center border-b border-r text-sm uppercase font-medium px-3 py-2"
            >
              <icon name="unfold-more" class="mr-1" />
              Seat
            </p>
            <p
              v-if="template.data.eventDetails.section"
              class="flex-1 text-center border-b text-sm uppercase font-medium px-3 py-2"
            >
              <icon name="unfold-more" class="mr-1" />
              Section
            </p>
          </div>
          <div class="grow overflow-y-scroll -mr-3">
            <div
              v-for="(recipient, recipientIndex) in ticketRecipients"
              :key="recipient"
              class="w-full flex relative"
            >
              <div
                v-for="(modelKey, modelKeyIndex) in [
                  'email',
                  ...(template.data.eventDetails.name ? ['name'] : []),
                  ...(template.data.eventDetails.seat ? ['seat'] : []),
                  ...(template.data.eventDetails.section ? ['section'] : []),
                ]"
                class="flex-1 text-sm uppercase py-1 px-0.5"
                :class="{
                  'border-b': recipientIndex != ticketRecipients.length - 1,
                  'border-r': modelKeyIndex != 3,
                }"
              >
                <input
                  class="w-full p-0 text-sm text-center border-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 cursor-pointer rounded-md"
                  v-model="ticketRecipients[recipientIndex][modelKey]"
                />
              </div>
              <div
                v-if="ticketRecipients.length > 1"
                @click="ticketRecipients.splice(recipientIndex, 1)"
                class="absolute right-0 cursor-pointer mr-1"
              >
                <icon name="cross" class="w-3 fill-gray-300" />
              </div>
            </div>
            <div
              @click="
                () => {
                  ticketRecipients.push({
                    email: '',
                    name: '',
                    seat: '',
                    section: '',
                  });
                }
              "
              class="flex justify-center bg-gray-50 border-t cursor-pointer py-2"
            >
              <p class="text-sm text-gray-400">
                <icon name="plus" class="fill-gray-400 mr-1.5" />Ajouter un
                destinataire
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center mb-8">
          <button
            class="text-sm bg-white border shadow-sm w-max font-medium rounded-lg cursor-pointer align-middle items-center py-1.5 px-2 mr-2"
            @click="$refs.emailFileInput.click()"
          >
            <icon name="file-upload" class="mr-1.5" />{{
              $t('templateSend.importButton')
            }}
          </button>
          <input
            type="file"
            @change="importEmailFileInput"
            ref="emailFileInput"
            class="hidden"
          />
          <p class="text-xs text-gray-500">
            {{ $t('templateSend.importConstraintsTxt') }}
          </p>
          <p>{{ emailFileMessage }}</p>
        </div>
        <div class="flex items-center">
          <div class="grow"></div>
          <button
            @click="sendMultipleCustomLink"
            class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5"
          >
            {{ $t('templateSend.sendButton') }}
          </button>
        </div>
      </div>
      <div
        v-else
        class="fixed w-modal-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white border rounded-xl shadow-md"
      >
        <div class="px-5 py-2">
          <div class="flex items-center">
            <p class="text-gray-500 my-2">
              {{
                $t('templateSend.sendTxt') + ' ' + templateLabel.toLowerCase()
              }}
              /
              <span class="font-medium text-black">
                {{ $t('templateSend.chooseTemplateTxt') }}</span
              >
            </p>
            <div class="grow"></div>
            <div
              @click="triggerCloseEvent"
              class="text-xl text-gray-400 mr-2 cursor-pointer"
            >
              <icon name="cross" />
            </div>
          </div>
          <input
            class="w-full !text-xl border-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 my-2"
            :placeholder="$t('search.searchPlaceholder')"
            maxlength="50"
            v-model="badgeTemplateSearchTerms"
          />
        </div>
        <hr class="border border-bottom" />
        <div class="px-5 pt-2 relative">
          <div
            class="flex flex-wrap w-full gap-4 overflow-y-scroll no-scrollbar h-[28rem] pb-3"
          >
            <div
              v-for="(template, index) in filteredTemplateList"
              :key="template"
              class="rounded-2xl h-min"
            >
              <TemplatePreview
                v-if="template.data && currentIssuerInfo"
                :templateImage="template.data.image"
                :templateName="template.data.name"
                :templateLocation="
                  type == 'Participation' || type == 'Ticket'
                    ? template.data.eventDetails.location.split(', ')[0]
                      ? template.data.eventDetails.location.split(', ')[0]
                      : $t('templateDisplay.virtualTxt')
                    : null
                "
                :templateStartDate="
                  type == 'Participation' || type == 'Ticket'
                    ? template.data.eventDetails.startDate
                    : null
                "
                :templateEndDate="
                  type == 'Participation' || type == 'Ticket'
                    ? template.data.eventDetails.endDate
                    : null
                "
                :templateHexColor="template.data.hexColorRef"
                :issuerName="currentIssuerInfo.name"
                :type="type"
                :templateContractState="template.contractState"
                :formatSm="type != 'Membership'"
                :enableSelection="true"
                :selected="currentSelectedTemplateIndex == index"
                @click="currentSelectedTemplateIndex = index"
                class="cursor-pointer"
              />
            </div>
          </div>
          <div class="absolute bottom-0 right-0 flex items-center">
            <div class="grow"></div>
            <button
              @click="
                template =
                  filteredTemplateList[currentSelectedTemplateIndex].data
              "
              class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5 mr-3 mb-2"
            >
              {{ $t('templateSend.selectButton') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="waitingSignature">
      <BasicSmModal @close="waitingSignature = false">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-app.jpg')"
            :height="160"
            :width="160"
            :speed="0.7"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-1">
          {{ $t('templateSend.confirmDelegCreateTxt') }}
        </p>
        <p class="text-center px-10 mb-4">
          {{ $t('templateSend.onMydidAppTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="sendingInProgress">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-gray-400 px-10">
          {{ templateLabel + ' ' + $t('templateSend.sendProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="sendingSuccessful">
      <BasicSmModal @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-send.jpg')"
            :height="160"
            :width="160"
            :speed="1"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateSend.successSendTxt') }}
        </p>
      </BasicSmModal>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import TemplatePreview from '../../components/templates/TemplatePreview.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
export default {
  props: {
    templateList: Array,
    template: Object,
    type: String,
  },
  setup(props, context) {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );

    const templateLabel = ref(utils.templateTypeToLabel(props.type, useI18n()));

    const badgeTemplateSearchTerms = ref(null);
    const currentSelectedTemplateIndex = ref(0);
    const emailInput = ref(null);
    const emailList = ref([]);
    const badgeComment = ref(null);
    const emailFile = ref(null);
    const emailFileMessage = ref(null);
    const MAX_SIZE_IN_BYTES = 20 * 1024 * 1024;
    const emailListScrollDiv = ref(null);
    const ticketRecipients = ref([
      { email: '', name: '', seat: '', section: '' },
    ]);

    const waitingSignature = ref(false);
    const sendingInProgress = ref(false);
    const sendingSuccessful = ref(false);

    async function sendMultipleCustomLink() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.template.data.badgeCategory}`,
          'Send multiple custom links',
          'Confirm',
        ]);

      // filter emails
      let validEmails = [];
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      if (props.template.data.badgeCategory != 'Ticket') {
        validEmails = emailList.value.filter((email) => emailRegex.test(email));
      } else {
        validEmails = ticketRecipients.value
          .map((recipient) => recipient.email)
          .filter((email) => emailRegex.test(email));
      }

      if (validEmails.length == 0) {
        utils.showToastMessageError('No valid emails');
        return;
      }

      waitingSignature.value = true;

      let endDate =
        new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (props.type == 'Participation' || props.type == 'Ticket') {
        endDate =
          new Date(props.template.data.eventDetails.endDate)
            .toISOString()
            .slice(0, 19) + 'Z';
      }

      api
        .createSession({
          templateId: props.template.id,
          didLimit: 1,
          sessionNumber: validEmails.length,
          emails: validEmails,
          endDate,
          ...(props.template.data.eventDetails && {
            startDate: props.template.data.eventDetails.startDate,
          }),
          ...(props.template.data.eventDetails &&
            props.template.data.eventDetails.limit != -1 && {
              didLimit: props.template.data.eventDetails.limit,
            }),
          ...(props.template.data.badgeCategory == 'Ticket' && {
            options: validEmails.map((email) => {
              const recipient = ticketRecipients.value.find(
                (recip) => recip.email == email
              );
              return {
                name: recipient.name,
                seat: recipient.seat,
                section: recipient.section,
              };
            }),
          }),
        })
        .then(() => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.template.data.badgeCategory}`,
              'Send multiple custom links',
              'Success',
            ]);
          waitingSignature.value = false;
          sendingSuccessful.value = true;
          context.emit('refreshTemplate', props.template.id);
        })
        .catch((err) => {
          if (err.response?.data?.code == 2000) {
            utils.showToastMessageError('Too many emails sent in the last 24h');
          } else {
            utils.showToastMessageError(t('other.serverError'));
          }
          waitingSignature.value = false;
        });
    }

    const filteredTemplateList = computed(() => {
      const list = [];
      if (!props.templateList) return list;

      for (var template of props.templateList) {
        if (
          (badgeTemplateSearchTerms.value &&
            badgeTemplateSearchTerms.value.length > 0 &&
            template.data.name
              .toLowerCase()
              .indexOf(badgeTemplateSearchTerms.value.toLowerCase()) != -1) ||
          !badgeTemplateSearchTerms.value ||
          badgeTemplateSearchTerms.value.length < 2
        ) {
          list.push({ data: template.data });
        }
      }
      if (currentSelectedTemplateIndex.value > list.length - 1) {
        currentSelectedTemplateIndex.value = 0;
      }

      while (list.length < 4) {
        list.push({});
      }

      return list;
    });

    async function importEmailFileInput(event) {
      const file = event.target.files[0];

      if (
        ['text/csv', 'application/vnd.ms-excel'].indexOf(file['type']) == -1
      ) {
        emailFile.value = null;
        emailFileMessage.value = 'Only csv files are accepted.';
        return;
      }

      if (file.size > MAX_SIZE_IN_BYTES) {
        emailFile.value = null;
        emailFileMessage.value = 'Max size exceeded.';
        return;
      }

      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.template.data.badgeCategory}`,
          'Import email list',
          'Success',
        ]);

      emailFile.value = file;
      emailFileMessage.value = null;
      const reader = new FileReader();
      reader.onload = async (e) => {
        let content = e.target.result;

        function parseCSV(content) {
          const lines = content
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);
          const headers = lines[0].split(',').map((header) => header.trim());

          const data = lines.slice(1).map((line) => {
            const values = line.split(',').map((value) => value.trim());
            const entry = {};
            headers.forEach((header, index) => {
              entry[header] = values[index] || null;
            });
            return entry;
          });

          return data;
        }

        const data = parseCSV(content);

        if (props.template.data.badgeCategory == 'Ticket') {
          ticketRecipients.value = data;
        } else {
          emailList.value = data.map((el) => el.email);
        }

        setTimeout(() => {
          emailListScrollDiv.value
            ? (emailListScrollDiv.value.scrollTop = 0)
            : null;
        }, 100);
      };
      reader.readAsText(emailFile.value);
    }

    function treatEmailInput() {
      if (!emailInput.value) return;
      const potentialEmails = emailInput.value.split(/[\s,;]+/);
      const validEmails = potentialEmails.filter((email) =>
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
      );

      emailList.value = [...new Set([...emailList.value, ...validEmails])]; // new Set() removes duplicate
      emailInput.value = null;
    }

    function removeFromEmailList(index) {
      emailList.value.splice(index, 1);
    }

    function triggerCloseEvent() {
      context.emit('close');
    }

    return {
      currentIssuerInfo,
      triggerCloseEvent,
      badgeTemplateSearchTerms,
      filteredTemplateList,
      currentSelectedTemplateIndex,
      emailInput,
      emailList,
      treatEmailInput,
      removeFromEmailList,
      badgeComment,
      emailFileMessage,
      importEmailFileInput,
      emailListScrollDiv,
      waitingSignature,
      sendingSuccessful,
      sendingInProgress,
      sendMultipleCustomLink,
      templateLabel,
      ticketRecipients,
    };
  },
  components: {
    TemplatePreview,
    BasicSmModal,
  },
};
</script>
